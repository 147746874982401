import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, IconButton,Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add'; // Import the delete icon
import InputField from './InputField';

function Section({ section, updateSection, toggleCollapse, deleteSection }) {
  const [title, setTitle] = useState(section.title);
  const [inputs, setInputs] = useState(section.inputs);

  const fileInputExists = () => {
    return inputs.some(input => input.type === 'file');
  };

  const addInput = () => {
    setInputs([...inputs, { type: 'text', required: false, label: '' }]);
  };

  const updateInput = (index, newInput) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = newInput;
    setInputs(updatedInputs);
    updateSection({ title, inputs: updatedInputs });
  };

  const deleteInput = (index) => {
    const updatedInputs = inputs.filter((_, i) => i !== index);
    setInputs(updatedInputs);
    updateSection({ title, inputs: updatedInputs });
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    updateSection({ title: e.target.value, inputs });
  };

  return (
    <Box sx={{ mt: 4, p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
      <Typography variant="h6" onClick={toggleCollapse} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', mb: 2  }}>
        <ExpandMoreIcon sx={{ transform: section.isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s' }} />
        {title || 'Seção'}
      </Typography>
      
      <Collapse in={!section.isCollapsed}>
        <TextField
          label="Titulo da seção"
          fullWidth
          value={title}
          onChange={handleTitleChange}
          sx={{ mb: 2 }}
        />

        <Divider sx={{ my: 2 }} />

        {inputs.map((input, index) => (
          <InputField
            key={`${index}_${input.label}`}
            input={input}
            updateInput={(newInput) => updateInput(index, newInput)}
            deleteInput={() => deleteInput(index)} // Pass deleteInput handler
            fileInputExists={fileInputExists}
          />
        ))}

      <IconButton color="outlined" onClick={addInput}>
        <AddIcon />
      </IconButton>
        <div style={{clear: 'both'}} />
        <Button variant="outlined" color="error" onClick={deleteSection} sx={{ mt: 2 }}>
          Apagar seção
        </Button>
      </Collapse>
    </Box>
  );
}

export default Section;
