import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Grid,
    IconButton
} from '@mui/material';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import the refresh icon
import { url } from '../../utils/constants';


function CourtesyCustomers() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ignoreDate, setIgnoreDate] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchCourtesyCustomers();
    }, []);


    const fetchCourtesyCustomers = async () => {
        try {
            setLoading(true);

            const token = localStorage.getItem('token');
            const response = await axios.get(`${url}/api/reports/courtesyCustomers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const data = response.data.map((item, index) => ({
                ...item,
                id: index + 1, // Adding a unique ID for the DataGrid
                ISFuncionario: item.ISFuncionario === 1 ? 'Sim' : 'Não',
                ISParceiro: item.ISParceiro === 1 ? 'Sim' : 'Não',
                ISFormadorOpiniao: item.ISFormadorOpiniao === 1 ? 'Sim' : 'Não',
            }));
            

            setRows(data);
        } catch (error) {
            console.error('Error fetching forms:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Clientes cortesia
                </Typography>
                {
                    !loading &&
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <IconButton onClick={fetchCourtesyCustomers} aria-label="Recarregar">
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Paper elevation={3} sx={{ height: 600, padding: 3 }}>
                    <DataGrid
                        rows={rows}
                        columns={[
                            { field: 'Nome', headerName: 'Nome', width: 250 },
                            { field: 'CPF', headerName: 'CPF', width: 150 },
                            { field: 'CNPJ', headerName: 'CNPJ', width: 150 },
                            { field: 'TipoPessoa', headerName: 'Tipo', width: 220 },
                            {
                                field: 'ISFuncionario',
                                headerName: 'Funcionário?',
                                width: 90,
                            },
                            {
                                field: 'ISParceiro',
                                headerName: 'Parceiro?',
                                width: 90,
                            },
                            {
                                field: 'ISFormadorOpiniao',
                                headerName: 'Formador de opinião?',
                                width: 120,
                            },
                            { field: 'EndEmail', headerName: 'E-mail', width: 200 },
                        ]}
                        pageSize={5}
                        checkboxSelection
                        loading={loading}
                        slots={{ toolbar: GridToolbar }}
                    />

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default CourtesyCustomers;
