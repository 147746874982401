import { getUserEmail } from "../../src/authService";
import { reports } from "./reports";

const url = 'https://forms-api.redeatel.com.br'
const roles = {
    "_1": {'name': 'administrador'},
    "_2": {'name': 'editor'},
    "_3": {'name': 'visualizador'},
}
const getReportByUuid = (uuid) => {
    const has = reports.find((report) => report.uuid === uuid)

    if(!has) return false;
    if(has && !has.allowed.includes(getUserEmail())) return false;

    return has;
};


export {
    url,
    roles,
    getReportByUuid
}