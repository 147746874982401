import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, TextField, Checkbox, FormControlLabel, Paper, RadioGroup, Radio, Button, Typography, Alert, CircularProgress, Container } from '@mui/material';
import axios from 'axios';
import MDEditor from '@uiw/react-md-editor';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie'; // Import the js-cookie library
import { url } from '../utils/constants';
import './FormRender.css';
import account from 'src/_mock/account';


const StyledPaper = styled(Paper)({
  background: 'linear-gradient(145deg, #f0f4f8, #ffffff)',
  borderRadius: '12px',
  padding: '24px',
  margin: '20px auto',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
});

const StyledButton = styled(Button)({
  padding: '12px 24px',
  borderRadius: '8px',
  fontWeight: 'bold',
  background: '#f39c12',
  color: '#fff',
  '&:hover': {
    background: '#e67e22',
  },
});

const ProgressContainer = styled(Box)({
  width: '100%',
  height: '8px',
  background: '#ddd',
  borderRadius: '4px',
  overflow: 'hidden',
  marginBottom: '16px',
});

const ProgressBar = styled(Box)(({ progress }) => ({
  width: `${progress}%`,
  height: '100%',
  background: '#27ae60',
  transition: 'width 0.3s ease-in-out',
}));



function QuizRender() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState([]);
  const [quizProfile, setQuizProfile] = useState([]);
  const [quizId, setQuizId] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [timeLeft, setTimeLeft] = useState(300);
  const [startTime, setStartTime] = useState(null); // Add startTime state
  const [leaveCount, setLeaveCount] = useState(0); // Track leave count in the state


  // Handle answer selection
  const handleAnswerSelect = (questionIndex, answerIndex) => {
    setUserAnswers({
      ...userAnswers,
      [questionIndex]: answerIndex,
    });
  };

  // Navigate to the next question
  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  // Navigate to the previous question
  const handleBack = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  // Timer countdown effect
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(timer);
    } else {
      // Refresh page when time is up
      alert("Time is up! Restarting the quiz.");
      window.location.reload();
    }
  }, [timeLeft]);


  // Warn user before leaving the page
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = ''; // Required for the confirmation dialog
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // Reload page when tab is switched
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        const currentLeaveCount = parseInt(Cookies.get('leaveCount') || '0', 10) + 1;
        Cookies.set('leaveCount', currentLeaveCount, { secure: true }); // Increment and store leave count
        setLeaveCount(currentLeaveCount);

        window.location.reload(); // Reload the page when the user switches tabs
        //here we shall store in cookie or other secure location how many times the user leaved and clean once the quiz is submited 
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  // Reload page when mouse leaves the window
  // useEffect(() => {
  //   const handleMouseLeave = () => {
  //     //here we shall store in cookie or other secure location how many times the user leaved and clean once the quiz is submited 
  //     const currentLeaveCount = parseInt(Cookies.get('leaveCount') || '0', 10) + 1;
  //     Cookies.set('leaveCount', currentLeaveCount, { secure: true }); // Increment and store leave count
  //     setLeaveCount(currentLeaveCount);
  //   };

  //   window.addEventListener('mouseleave', handleMouseLeave);

  //   return () => {
  //     window.removeEventListener('mouseleave', handleMouseLeave);
  //   };
  // }, []);

  // Submit handler to calculate the score
  const handleSubmit = async () => {
    let calculatedScore = 0;

    quizData.forEach((question, index) => {
      const correctAnswerIndex = question.answers.findIndex(answer => answer.isCorrect);
      if (userAnswers[index] === correctAnswerIndex) {
        calculatedScore++;
      }
    });

    //here we'll get the cookies value 
    const leaveCountValue = Cookies.get('leaveCount') || 0;

    console.log(leaveCountValue,'leaveCountValue')

    const post = {
      answers: userAnswers,
      score: calculatedScore,
      quizId: quizId,
      timer:  Math.floor((Date.now() - startTime) / 1000),
      leaveCount: parseInt(leaveCountValue, 10),

    }

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`, // Send the token in the header
    }

    try {
      const response = await axios.post(`${url}/quizAnswer`, post, { headers: { ...headers } });

      if (response.status === 201) {
        await Swal.fire({
          title: 'Resposta enviada com sucesso.',
          icon: 'success',
          timer: 4000,
        });

        setScore(calculatedScore);
      }

      if (response.status === 200) {
        await Swal.fire({
          title: 'Resposta enviada com sucesso, porém não computada',
          icon: 'success',
          timer: 4000,
        });

        setScore(calculatedScore);
      }

      ////here we'll delete the cookies
      Cookies.remove('leaveCount');

    } catch (error) {
      await Swal.fire({
        title: 'Falha ao processar solicitação',
        icon: 'error',
        text: 'Ocorreu um erro ao enviar seu pedido, tente novamente ou contacte o administrador do sistema',
        timer: 4000,
      });
    }
  };

  useEffect(() => {
    setStartTime(Date.now()); // Set the start time when the component mounts
  }, []);

  const isLastQuestion = currentQuestionIndex === quizData.length - 1;
  const currentQuestion = quizData[currentQuestionIndex];

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ item, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ item }) => item);
  };

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/quiz/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        const shuffledQuestions = shuffleArray(Object.values(response.data.json_content));
        setQuizData(shuffledQuestions);
        setQuizProfile(response.data);
        setQuizId(response.data.id);
        setTimeLeft(response.data.timer === 0 ? 600 : response.data.timer);

      } catch (error) {
        navigate('/404');
        console.error('Error fetching forms:', error);
      }
    };

    fetchQuiz();
  }, [id, navigate, setQuizId]);

  // Render the floating timer
  const renderClock = () => {
    const totalTime = 300; // Total time in seconds (5 minutes)
    const timePercent = (timeLeft / totalTime) * 100;
  
    const progressColor = timeLeft < 60 ? 'red' : 'green'; // Green unless less than 60 seconds
  
    return (
      <Box
        position="absolute"
        top="20px"
        right="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ width: '80px', height: '80px' }}
      >
        <CircularProgress
          variant="determinate"
          value={100} // Gray background
          size={80}
          thickness={5}
          style={{
            position: 'absolute',
            color: 'gray', // Default gray background
          }}
        />
        <CircularProgress
          variant="determinate"
          value={timePercent}
          size={80}
          thickness={5}
          style={{
            position: 'absolute',
            color: progressColor, // Green or red based on time
          }}
        />
        <Typography
          variant="h6"
          style={{
            position: 'relative',
            zIndex: 1,
            fontWeight: 'bold',
          }}
        >
          {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
        </Typography>
      </Box>
    );
  };
  

  if (!quizData.length) {
    return (
      <Container maxWidth="xl" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
  <Container maxWidth="md" style={{ 
    textAlign: 'center',
    }}>
    {renderClock()}
    <StyledPaper elevation={3}>
      <Typography variant="h4" gutterBottom style={{ color: '#34495e' }}>
        {quizProfile.title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Preenchendo quiz como <b style={{ color: '#f39c12' }}>{account.displayName}</b>
      </Typography>

      {score !== null ? (
        <Box mt={4}>
          <Alert severity="info" style={{ fontWeight: 'bold' }} aria-live="polite">
            Sua pontuação: {score} / {quizData.length}
          </Alert>
          <StyledButton onClick={() => window.location.reload()} style={{ marginTop: '20px' }}>
            Refazer quiz
          </StyledButton>
        </Box>
      ) : (
        <>
          <ProgressContainer>
            <ProgressBar progress={((currentQuestionIndex + 1) / quizData.length) * 100} />
          </ProgressContainer>
          <Typography variant="h5" gutterBottom style={{ marginBottom: '16px', color: '#2c3e50' }}>
            {`Questão ${currentQuestionIndex + 1} / ${quizData.length}`}
          </Typography>
          <Typography variant="body1" paragraph style={{ fontSize: '18px' }}>
            {currentQuestion.question}
          </Typography>
          {currentQuestion.description && (
            <Paper
              style={{
                padding: '12px',
                background: '#ecf0f1',
                marginBottom: '20px',
                borderRadius: '8px',
              }}
            >
              <MDEditor.Markdown source={currentQuestion.description} />
            </Paper>
          )}
          <RadioGroup
            name={`question-${currentQuestionIndex}`}
            value={userAnswers[currentQuestionIndex] ?? null}
            onChange={(e) => handleAnswerSelect(currentQuestionIndex, parseInt(e.target.value))}
          >
            {currentQuestion.answers.map((answer, index) => (
              <FormControlLabel
                key={index}
                value={index}
                control={<Radio />}
                label={answer.text}
                style={{
                  margin: '8px 0',
                  padding: '8px',
                  background: userAnswers[currentQuestionIndex] === index ? '#d1e7dd' : 'transparent',
                  border: userAnswers[currentQuestionIndex] === index ? '2px solid #0f5132' : '1px solid #ced4da',
                  boxShadow: userAnswers[currentQuestionIndex] === index ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                  borderRadius: '8px',
                  border: '1px solid #ced4da',
                }}
              />
            ))}
          </RadioGroup>
          <Box mt={4} display="flex" justifyContent="space-between">
            <StyledButton
              onClick={handleBack}
              disabled={currentQuestionIndex === 0}
            >
              Voltar
            </StyledButton>
            <StyledButton onClick={isLastQuestion ? handleSubmit : handleNext}>
              {isLastQuestion ? 'Enviar' : 'Próxima'}
            </StyledButton>
          </Box>
        </>
      )}
    </StyledPaper>
  </Container>
  );
}

export default QuizRender;
