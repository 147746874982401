import CantSuspend from "src/components/reports/CantSuspend"
import CourtesyCustomers from "src/components/reports/CourtesyCustomers"
import CustomersWithoutInvoices from "src/components/reports/CustomersWithoutInvoices"
import DuplicatedDefaultAddress from "src/components/reports/DuplicatedDefaultAddress"
import EnabledEmpoyers from "src/components/reports/EnabledEmployers"

const ADAPTER = 'adapter'
const HANA = 'hana'


const reports = [
    {
        'uuid': '9876gyhlys7',
        'name': 'Funcionários ativos',
        'description': 'Lista todos os funcionários da empresa, cadastrados no Adapter',
        'source': ADAPTER,
        'query': '',
        'allowed': ['mauro@ateltelecom.com.br', 'josivania@ateltelecom.com.br'],
        'status': 1,
        'element': <EnabledEmpoyers />
    },
    {
        'uuid': '9876gyhlys6',
        'name': 'Endereço padrão duplicado',
        'description': 'Lista todos os contratos com endereço PADRÃO duplicado',
        'source': ADAPTER,
        'query': '',
        'allowed': ['mauro@ateltelecom.com.br', 'josivania@ateltelecom.com.br'],
        'status': 1,
        'element': <DuplicatedDefaultAddress />
    },
    {
        'uuid': '9876gyhlys5',
        'name': 'Clientes sem fatura',
        'description': 'Lista todos os clientes sem fatura gerada, por contrato',
        'source': ADAPTER,
        'query': '',
        'allowed': ['mauro@ateltelecom.com.br', 'josivania@ateltelecom.com.br'],
        'status': 1,
        'element': <CustomersWithoutInvoices />
    },
    {
        'uuid': '9876gyhlys4',
        'name': 'Clientes que não pode suspender',
        'description': 'Lista todos os clientes com a flag PODE SUSPENDER desmarcada',
        'source': ADAPTER,
        'query': '',
        'allowed': ['mauro@ateltelecom.com.br', 'josivania@ateltelecom.com.br', 'danilo@ateltelecom.com.br', 'gabriela@ateltelecom.com.br', 'diego@ateltelecom.com.br'],
        'status': 1,
        'element': <CantSuspend />
    },
    {
        'uuid': '9876gyhlys3',
        'name': 'Clientes cortesia',
        'description': 'Clientes marcados como funcionário, parceiro ou formado de opinião',
        'source': ADAPTER,
        'query': '',
        'allowed': ['mauro@ateltelecom.com.br', 'josivania@ateltelecom.com.br'],
        'status': 1,
        'element': <CourtesyCustomers />
    },
]

export {
    reports
}