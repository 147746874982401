import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';

import axios from 'axios';
import Swal from "sweetalert2"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { url } from '../utils/constants';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [form, setForm] = useState([])

  const generateChartData = () => {
    const labels = form.map(form => form.name); // Form names
    const dataPoints = form.map(form => form.answerCount); // Answer counts

    return {
      labels,
      datasets: [
        {
          label: 'Answers per Form',
          data: dataPoints,
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get(`${url}/api/forms`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const forms = response.data;

        setForm(forms)

      } catch (error) {
        // navigate('/404');
        console.error('Error fetching forms:', error);
      }
    };

    fetch();
  }, []);

  const totalResponses = form.reduce((acc, curr) => acc + curr.answerCount, 0);
  const inactiveForms = form.filter((f) => !f.status).length;
  return (
    <>
      <Helmet>
        <title> Dashboard | Atel Forms </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, bem-vindo novamente <span style={{color: '#f1c40f'}}>🖐</span>
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Forms criados" total={form.length} icon={'ant-design:form-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Respostas coletadas" total={totalResponses} color="info" icon={'ant-design:comment-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="formulários inativos" total={inactiveForms} color="warning" icon={'ant-design:form-outlined'} />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          <Grid item xs={12} md={6} lg={12} sx={{mt: 4}}>
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
              {form.length > 0 ? (
                <Doughnut
                  data={generateChartData()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      tooltip: {
                        callbacks: {
                          label: function(tooltipItem) {
                            return `${tooltipItem.label}: ${tooltipItem.raw} respostas`;
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
