import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Paper, IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import the refresh icon
import axios from 'axios';
import { url } from '../../utils/constants';

function DuplicatedDefaultAddress() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchDuplicatedDefaultAddress();
    }, []);

    const fetchDuplicatedDefaultAddress = async () => {
        try {
            setLoading(true);

            const token = localStorage.getItem('token');
            const response = await axios.get(`${url}/api/reports/duplicatedDefaultAddress`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setRows(
                response.data.map((item, index) => ({
                    ...item, // Spread the original data
                    id: index + 1, // Add a unique ID
                }))
            );
        } catch (error) {
            console.error('Error fetching forms:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Endereço padrão duplicado
                </Typography>
                {
                    !loading &&
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <IconButton onClick={fetchDuplicatedDefaultAddress} aria-label="Recarregar">
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
            <Paper elevation={3} sx={{ height: 400, padding: 3 }}>
                <DataGrid
                    rows={rows}
                    columns={[
                        { field: 'id', headerName: '#', width: 70 },
                        { field: 'IDContrato', headerName: 'Contrato', width: 120 },
                        { field: 'LoginRadius', headerName: 'Login', width: 280 },
                        { field: 'NomeUsuario', headerName: 'Vendedor', width: 280 },
                    ]}
                    pageSize={5}
                    checkboxSelection
                    loading={loading}
                    slots={{ toolbar: GridToolbar }}
                />
            </Paper>
        </Container>
    );
}

export default DuplicatedDefaultAddress;
