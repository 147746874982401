import { Alert, AlertTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

function TipsBlock() {
  const [showTips, setShowTips] = useState(!localStorage.getItem('hideTips'));

  const handleDismiss = () => {
    setShowTips(false);
    localStorage.setItem('hideTips', 'true');
  };

  return (
    showTips && (
      <Alert
        severity="info"
        sx={{ mb: 4 }}
        action={
          <IconButton color="inherit" size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>Dicas</AlertTitle>
        - Clique duas vezes no título do formulário para editá-lo.<br />
        - Certifique-se de que a data de expiração está pelo menos 5 minutos à frente.<br />
        - Use seções para organizar seus campos de formulário.
      </Alert>
    )
  );
}

export default TipsBlock;