import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Grid,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { url } from '../../utils/constants';

function CantSuspend() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ignoreDate, setIgnoreDate] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDateChange = (e, field) => {
        if (field === 'start') setStartDate(e.target.value);
        else if (field === 'end') setEndDate(e.target.value);
    };

    const handleIgnoreDateChange = (e) => {
        setIgnoreDate(e.target.checked);
        if (e.target.checked) {
            setStartDate('');
            setEndDate('');
        }
    };

    const fetchEnabledEmployers = async () => {
        try {
            setLoading(true);

            const params = {
                start_date: startDate || undefined,
                end_date: endDate || undefined,
                ignore_date: ignoreDate,
            };

            const token = localStorage.getItem('token');
            const response = await axios.get(`${url}/api/reports/cantSuspend`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params,
            });

            const data = response.data.map((item, index) => ({
                ...item,
                id: index + 1,
            }));

            setRows(data);
            generateChartData(data);
        } catch (error) {
            console.error('Error fetching forms:', error);
        } finally {
            setLoading(false);
        }
    };

    const generateChartData = (data) => {
        const cityCounts = data.reduce((acc, item) => {
            acc[item.NomeCidade] = (acc[item.NomeCidade] || 0) + 1;
            return acc;
        }, {});

        setChartData({
            labels: Object.keys(cityCounts),
            datasets: [
                {
                    label: 'Usuários por Cidade',
                    data: Object.values(cityCounts),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
            ],
        });
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Não pode suspender
                </Typography>
                <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={startDate}
                            onChange={(e) => handleDateChange(e, 'start')}
                            InputLabelProps={{ shrink: true }}
                            disabled={ignoreDate}
                            sx={{ width: '20%' }}
                        />
                        <TextField
                            label="Data Final"
                            type="date"
                            value={endDate}
                            onChange={(e) => handleDateChange(e, 'end')}
                            InputLabelProps={{ shrink: true }}
                            disabled={ignoreDate}
                            sx={{ width: '20%' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ignoreDate}
                                    onChange={handleIgnoreDateChange}
                                    color="primary"
                                />
                            }
                            label="Ignorar Data (Todos os períodos)"
                        />
                        <Button
                            variant="contained"
                            onClick={fetchEnabledEmployers}
                            sx={{ backgroundColor: '#2980b9', '&:hover': { backgroundColor: '#216f9c' } }}
                        >
                            Filtrar
                        </Button>
                    </Box>
                </Paper>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Paper elevation={3} sx={{ height: 400, padding: 3 }}>
                        <DataGrid
                            rows={rows}
                            columns={[
                                { field: 'Nome', headerName: 'Nome do cliente', width: 530 },
                                { field: 'IDCliente', headerName: 'Código', width: 90 },
                                { field: 'IDContrato', headerName: 'Contrato', width: 90 },
                                { field: 'NomeCidade', headerName: 'Cidade', width: 180 },
                                { field: 'UltimoFaturamento', headerName: 'Último faturamento', width: 170 },
                                { field: 'Dia', headerName: 'Dia de pagamento', width: 120 },
                                // { field: 'DataCadastro', headerName: 'Data de Cadastro', width: 200 },
                            ]}
                            pageSize={5}
                            checkboxSelection
                            loading={loading}
                            slots={{ toolbar: GridToolbar }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default CantSuspend;
