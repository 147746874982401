import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button,Paper } from '@mui/material';
import { getReportByUuid } from 'src/utils/constants';


function ReportsLoad() {
    const { uuid } = useParams(); // UUID passed as a route parameter
    const navigate = useNavigate();

    const report = getReportByUuid(uuid);

    if (!report) {
        return (
            <Box 
                sx={{
                    textAlign: 'center',
                    marginTop: '50px',
                    padding: '20px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h5" color="error" gutterBottom>
                    Relatório não encontrado.
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '20px' }}>
                    O relatório que você está procurando não existe ou pode ter sido removido.
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate('/dashboard/reports')}
                    sx={{
                        backgroundColor: '#2980b9',
                        '&:hover': {
                            backgroundColor: '#216f9c',
                        },
                        padding: '10px 20px',
                        fontSize: '16px',
                    }}
                >
                    Voltar para Relatórios
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Paper elevation={3} sx={{ padding: 3, backgroundColor: '#ffffff' }}>
            {report.element}
            </Paper>
        </Box>
    );
}

export default ReportsLoad;
