import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Paper, IconButton, Alert } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import the refresh icon
import axios from 'axios';
import { url } from '../../utils/constants';

function CustomersWithoutInvoices() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCustomersWithoutInvoices();
    }, []);

    const fetchCustomersWithoutInvoices = async () => {
        try {
            setLoading(true);

            const token = localStorage.getItem('token');
            const response = await axios.get(`${url}/api/reports/customersWithoutInvoices`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setRows(
                response.data.map((item, index) => ({
                    ...item, // Spread the original data
                    id: index + 1, // Add a unique ID
                }))
            );
        } catch (error) {
            console.error('Error fetching forms:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Clientes sem fatura
                </Typography>

                {
                    !loading &&
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <IconButton onClick={fetchCustomersWithoutInvoices} aria-label="Recarregar">
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
            {loading &&
            <Alert severity="info" sx={{ marginTop: 2, marginBottom: 2}}>
                    A busca pode demorar alguns segundos, seja paciente.
            </Alert>
            }
            <Paper elevation={3} sx={{ height: 400, padding: 3 }}>
                <DataGrid
                    rows={rows}
                    columns={[
                        { field: 'id', headerName: '#', width: 70 },
                        { field: 'IDCliente', headerName: 'Cliente', width: 120 },
                        { field: 'Nome', headerName: 'Nome cliente', width: 280 },
                        { field: 'Endereco', headerName: 'Logradouro', width: 200 },
                        { field: 'Bairro', headerName: 'Bairro', width: 200 },
                        { field: 'Cidade', headerName: 'Cidade', width: 280 },
                        { field: 'ContratadoEm', headerName: 'Contrato criado em', width: 280 },
                    ]}
                    pageSize={5}
                    checkboxSelection
                    loading={loading}
                    slots={{ toolbar: GridToolbar }}
                />
            </Paper>
        </Container>
    );
}

export default CustomersWithoutInvoices;
