import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Stack, Button, TextField, Grid, Card, CardMedia, CardContent, Paper, Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import { reports } from '../utils/reports';
import { getUserEmail } from '../authService';

function Reports() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredReports = reports.filter((report) =>
        report.name.toLowerCase().includes(searchTerm) &&
        report.allowed.includes(getUserEmail())
    );

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>Relatórios para todos | Atel Forms</title>
            </Helmet>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Relatórios disponíveis
                </Typography>
                <Button
                    onClick={() => { document.location.href = 'mailto:ti@ateltelecom.com.br?subject=Adicionar relatório personalizado&body=Solicito a adição de um novo relatório, conforme descrição abaixo: [descreva como deve se comportar o novo relatório]'; }}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                >
                    Solicitar novo
                </Button>
            </Stack>

            <Paper elevation={3} sx={{ padding: 3, backgroundColor: '#ffffff' }}>
                {/* Improved Search Bar */}
                <Box mb={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Pesquisar relatórios"
                        onChange={handleSearch}
                        value={searchTerm}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                '&:hover fieldset': {
                                    borderColor: '#2980b9',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#2980b9',
                                },
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                searchTerm && (
                                    <Button onClick={() => setSearchTerm('')} sx={{ marginRight: '8px' }}>
                                        Limpar
                                    </Button>
                                )
                            ),
                        }}
                    />
                </Box>

                {/* Cards Area */}
                {isLoading ? (
                    <Grid container spacing={3}>
                        {[...Array(4)].map((_, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Skeleton variant="rectangular" width="100%" height={200} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        {filteredReports.length > 0 ? (
                            filteredReports.map((report) => (
                                <Grid item xs={12} sm={6} md={3} key={report.uuid}>
                                    <Card
                                        onClick={() => navigate(`/dashboard/reports/${report.uuid}`)}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '100%',
                                            cursor: 'pointer',
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                                            },
                                        }}
                                    >
                                        <CardMedia
                                            component="div"
                                            sx={{
                                                height: 90,
                                                backgroundColor: '#2980b9',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {report.name}
                                        </CardMedia>
                                        <CardContent>
                                            <Typography variant="body2" gutterBottom sx={{ textAlign: 'center' }}>
                                                {report.description}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                                Fonte: {report.source.toUpperCase()}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center" color="text.secondary">
                                    Nenhum relatório encontrado.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Paper>
        </Container>
    );
}

export default Reports;
